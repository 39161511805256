import {React, createContext, useState, useMemo } from "react";

export const DiagnosticContext = createContext();

export const DiagnosticProvider = (props) => {
    const [diagnostic, setDiagnostic] = useState([]);
    const [invoice, setInvoice] = useState({});
    const [invoiceSelected, setInvoiceSelected] = useState({});
    const [selectedClient, setSelectedClient] = useState({});
    const [selectedMechanic, setSelectedMechanic] = useState({});
    const [selectedBrand, setSelectedBrand] = useState({});
    const [selectedModel, setSelectedModel] = useState({});

    const [clientName, setClientName] = useState('');
     const providerValue = useMemo(() => ({
        diagnostic, setDiagnostic,
        invoice, setInvoice,
        selectedClient, setSelectedClient,
        selectedMechanic, setSelectedMechanic,
        selectedBrand, setSelectedBrand,
        selectedModel, setSelectedModel,
        invoiceSelected, setInvoiceSelected,
        clientName, setClientName
     }), [diagnostic, invoice, invoiceSelected, clientName, selectedClient, selectedMechanic, selectedBrand, selectedModel]);
    return (
        <DiagnosticContext.Provider value={providerValue}>
            {props.children}
        </DiagnosticContext.Provider>
    )

}
