import React, { useEffect, useState } from "react";
import LoginForm from "../../components/LoginForm";

const LoginPage = () => {
    
    return (
        <LoginForm />
    )
}

export default LoginForm;